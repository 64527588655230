import React, { useState, memo, useEffect } from 'react';
import {
    SttContainer,
    SttLoading,
    SttAlerta,
    SttGrid,
    SttButton,
    SttPaper,
    SttBox,
    SttHeading,
    SttNotification,
    SttFileSelect,
    SttTranslate,
    SttTable,
    SttTableCell,
    SttTableHead,
    SttTableRow,
    SttTableBody,
    SttCircularProgress,
    SttTableSortLabel
} from '@stt-componentes/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getBearerHeaders } from '../requests';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        marginTop: theme.spacing(3),
    }
}));

const Alerta = memo((props) => {
    return <SttAlerta {...props} />;
});

const formatarData = (data) => {
    if (!data) return 'Não disponível';
    
    const formato = new Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
    
    return formato.format(new Date(data));
};

const LogSenha = ({ strings, user }) => {
    const classes = useStyles();

    //Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([
        {
            title: strings.ok,
            onClick: () => {
                setMostrarAlerta(false);
            }
        }
    ]);
    const [onClose, setOnClose] = useState(() => setMostrarAlerta(false));
    const [dadosSenha, setDadosSenha] = useState([]);
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);

    useEffect(() => {
        if (user.id) {
            axios
                .get(
                    `${global.gConfig.url_base_api_oauth2}/interno/log-senha`,
                    {
                        params: { idUsuario: user.id },
                        headers: getBearerHeaders()
                    }
                )
                .then((response) => {
                    const dados = response.data;
                    setDadosSenha(dados);
                    console.log('dados', dados);
                    setBuscaEmAndamento(true);

                })
                .catch((err) => {
                    setTituloAlerta('Erro');
                    setMensagemAlerta(
                        'Parece que você não possui histórico de logs'
                    );
                    setOpcoesAlerta([
                        {
                            title: 'Ok',
                            onClick: () => setMostrarAlerta(false)
                        }
                    ]);
                    setMostrarAlerta(true);
                    setOnClose(() => () => setMostrarAlerta(false));
                })
                .finally(() => {
                    setBuscaEmAndamento(false);
                });
        }
    }, []);

    return (
        <>
            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <div className={classes.tableWrapper}>
                        <SttTable>
                            <SttTableHead>
                                <SttTableRow>
                                    <SttTableCell colSpan={6} align="center">
                                        {user.nome}
                                    </SttTableCell>
                                </SttTableRow>
                                <SttTableRow>
                                    <SttTableCell colSpan={6} align="center">
                                        {strings.ultimaAutenticacao}: {formatarData(dadosSenha.ultimaAutenticacaoSucesso)}
                                    </SttTableCell>
                                </SttTableRow>

                                <SttTableCell width='90%' >
								    {strings.data}
											
								</SttTableCell>
                                <SttTableCell>
								    {strings.tentativaDeAutenticacao}		
								</SttTableCell>
                            </SttTableHead>
                            <SttTableBody>
                                {buscaEmAndamento ? (
                                    <SttTableRow>
                                        <SttTableCell colSpan={6} align="center">
                                            <SttCircularProgress />
                                        </SttTableCell>
                                    </SttTableRow>
                                ) : dadosSenha.length === 0 || null ? (
                                    <SttTableRow>
                                        <SttTableCell colSpan={6} align="center">
                                            {strings.nenhumRegistroEncontrado}
                                        </SttTableCell>
                                    </SttTableRow>
                                ) : (
                                        dadosSenha.tentativasSemSucesso.map((row, index) => (
                                        <SttTableRow key={index}>
                                            <SttTableCell>
                                                {formatarData(row.data_hora_tentativa_autenticacao)}
                                            </SttTableCell>
                                            <SttTableCell>
                                                {row.autenticacao_sucedida === false ? strings.falhaNaAutenticacao : ''}
                                            </SttTableCell>
                                        </SttTableRow>
                                    ))
                                        
                                        
                                    
                                )}
                            </SttTableBody>
                        </SttTable>
                    </div>
                </SttGrid>
            </SttGrid>

            <Alerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onClose}
            />
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps)(
    SttTranslate('LogSenha')(withRouter(LogSenha))
);
