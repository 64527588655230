module.exports = {
    acompanhanteRegistrado: 'Acompanhante já registrado',
    atencaoAdicaoAnexos: 'Anexe aqui os documentos do paciente para a avaliação clínica.',
    cancelado: 'Cancelado',
    rascunho: 'Rascunho',
    avaliacaoClinica: 'Avaliação clínica',
    carregandoAvaliacaoClinica: 'Carregando avaliação clínica',
    novaAvaliacaoClinica: 'Nova avaliação clínica',
    atencaoAnexosObrigatorios: 'Os documentos de identidade com CPF, cartão nacional de saúde, comprovante de residência, termo de responsabilidade SMS, assinado e resultado da gasometria arterial são obrigatórios.',
    cardiopatias: 'Cardiopatias',
    doencaAutoimune: 'Doença autoimune',
    doencaNeurologica: 'Doença neurológica',
    doencasPulmonares: 'Doenças pulmonares',
    exFumante: 'Ex-fumante',
    neoplasia: 'Neoplasia',
    traqueostomia: 'Traqueostomia',
    pacienteInternado: 'Paciente internado',
    parentesco: 'Grau de parentesco',
    pesquisaAvaliacoesClinicas: 'Pesquisa de Avaliações Clínicas',
    gasometriaArterial: 'Gasometria arterial',
    fluxoOxigenio: 'Fluxo de Oxigênio',
    fumante: 'Fumante',
    gasometriaArterial: 'Gasometria arterial',
    historicoSaude: 'Histórico de saúde',
    identificacaoResponsavel: 'Identificação do responsável',
    informacoesClinicas: 'Informações clínicas',
    neoplasia: 'Neoplasia',
    nuncaFumou: 'Nunca fumou',
    outrasCheckbox: 'Outra(s)',
    outroParentesco: 'Outro parentesco',
    pacienteInternado: 'Paciente internado',
    parecerSituacao: 'Parecer da situação',
    parentesco: 'Grau de parentesco',
    rascunho: 'Rascunho',
    resultados: 'Resultados',
    solicitacaoAvaliacaoClinica: 'Solicitação de avaliação clínica',
    tempoUsoOxigenio: 'Tempo de uso do oxigênio (h/dia)',
    traqueostomia: 'Traqueostomia',
    tratamentosAndamento: 'Tratamentos em andamento',
    veracidadeParecerSituacao: 'Assumo a veracidade das informações acima descritas, com base em visita domiciliar, documentação apresentada, bem como das declarações recebidas do paciente.',
};