import { useState } from 'react';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import translate from '../translate';
import { getHeaders } from '../requests';
import { Cache } from '@stt-componentes/cache';
import { SttAlerta, SttLoading } from '@stt-componentes/core';
import SttSenha from '@stt-componentes/senha';

const TrocaForcadaSenhaForm = ({
    strings,
    usuario: dadosVerificacaoSenha,
    accessToken,
    responseType,
    stateVariable,
    redirectBaseUrl,
    mostrarModalSenha,
    setMostrarModalSenha
}) => {
    //Alerta
    const [openCarregando, setOpenCarregando] = useState(false);
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [opcoesAlerta, setOpcoesAlerta] = useState([
        {
            title: strings.ok,
            onClick: () => {
                setMostrarAlerta(false);
            }
        }
    ]);
    const [onClose, setOnClose] = useState(() => setMostrarAlerta(false));

    const { nomeUsuario } = dadosVerificacaoSenha || '';

    const initialValues = {
        novaSenha: '',
        senhaAtual: '',
        confirmacaoSenha: ''
    };

    const trocaObrigatoriaDeSenha = (dados, setSubmitting, resetForm) => {

        const oauthBaseUrl = global.gConfig.url_base_api_oauth2;

        setMostrarAlerta(false);
        setSubmitting(true);
        setOpenCarregando(true);

        axios
            .post(`${oauthBaseUrl}/interno/update-user-password`, dados, {
                headers: getHeaders(accessToken)
            })
            .then(function (response) {

                resetForm({ values: initialValues });
                
                const { data } = response;

                const newAccessToken = data.access_token;
                const newRefreshToken = data.refresh_token;

                let redirectUrl = `${redirectBaseUrl}?token=${newAccessToken}${stateVariable}`;
                if (responseType === 'code') {
                    redirectUrl = `${redirectBaseUrl}&code=${newAccessToken}`;
                }

                setSubmitting(false);
                setTituloAlerta(strings.sucesso);
                setMensagemAlerta(strings.senhaAlteradaComSucesso);
                setTipoAlerta('success');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                            setMostrarModalSenha(false);

                            Cache.setAccessToken(newAccessToken);
                            Cache.setRefreshToken(newRefreshToken);
                            window.location.assign(redirectUrl);
                        }
                    }
                ]);
                setOnClose(() => () => {
                    setMostrarAlerta(false);
                    setMostrarModalSenha(false);
                });
                setMostrarAlerta(true);
            })
            .catch(function (err) {
                const { response } = err;
                setSubmitting(false);

                let mensagem = strings.erro;
                if (response) {
                    const { data } = response;
                    mensagem = data.message;
                    if (
                        response.status === HttpStatus.BAD_REQUEST ||
                        response.status === HttpStatus.INTERNAL_SERVER_ERROR
                    ) {
                        let arrMensagem = [];

                        if (data.errors) {
                            data.errors.forEach((error) => {
                                if (error.message) {
                                    arrMensagem.push(`- ${error.message}`);
                                } else {
                                    arrMensagem.push(`- ${error}`);
                                } 
                            });
                        }

                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => setMostrarAlerta(false)
                    }
                ]);
                setOnClose(() => () => setMostrarAlerta(false));
                setMostrarAlerta(true);
            })
            .finally(() => {
                setOpenCarregando(false);
            });
    };

    return (
        <>
            <SttLoading open={openCarregando} text={strings.salvando} />

            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onClose}
            />

            <SttSenha
                modal={true}
                titulo={strings.tituloModal}
                openSenha={mostrarModalSenha}
                setOpenSenha={setMostrarModalSenha}
                strings={strings}
                dadosUsuario={dadosVerificacaoSenha}
                notificacao={`${strings.textoAtencao}, ${nomeUsuario}! ${strings.textoModalTroqueSuaSenha}`}
                enviarForm={trocaObrigatoriaDeSenha}
            />
        </>
    );
};

export default translate('TrocaForcadaSenhaForm')(TrocaForcadaSenhaForm);
