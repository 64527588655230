import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { setAuthenticated as setAuthenticatedAction, setUser as setUserAction } from '../reducers/actions';
import { Switch, Route } from 'react-router-dom';
import Menu from '../components/menu';
import { SttCircularProgress } from '@stt-componentes/core';
import { SttCacheManager } from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import MeusDados from './meus-dados/index';
import ModuloAcesso from './modulos-acesso';
import AlteracaoSenha from './form-alteracao-senha';
import AlteracaoFoto from './form-alteracao-foto';
import logSenha from './logSenha';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    },
    tableWrapper: {
        marginTop: theme.spacing(3)
    }
}));

const Interno = ({ isAuthenticated, setAuthenticated, setUser }) => {
    const classes = useStyles();

    return (
        <div>
            {
                isAuthenticated
                    ?
                    <div className={classes.wrapper}>
                        <Menu />
                        <Switch>
                            <Route path="/inicio" exact component={ModuloAcesso} />
                            <Route path="/meus-dados" exact component={MeusDados} />
                            <Route path="/alteracao-senha" exact component={AlteracaoSenha} />
                            <Route path="/alteracao-foto" exact component={AlteracaoFoto} />
                            <Route path="/log-senha" exact component={logSenha} />
                        </Switch>
                    </div>
                    :
                    <>
                        <div className={classes.loading}>
                            <SttCircularProgress />
                        </div>
                        <SttCacheManager config={global.gConfig} onSync={loaded => {
                            setAuthenticated(loaded);

                            const userInfo = Cache.getUserInfo();
                            setUser(userInfo);
                        }} />
                    </>
            }
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.index.isAuthenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthenticated: (authenticated) => dispatch(setAuthenticatedAction(authenticated)),
        setUser: (user) => dispatch(setUserAction(user))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interno);