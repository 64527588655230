module.exports = {
    dataAcao: 'Data da ação',
    dataUltimaAcao: 'Data da última ação',
    identificador: 'Identificador',
    integracaoExames: 'Integrações de Exames',
    mensagem: 'Mensagem',
    notificacao: 'Notificação',
    numeroExame: 'Número do exame',
    status: 'Status',
    pacs_aetitle: 'PACS AE Title',
    tituloVisualizacao: 'Visualização de histórico',
    urlPacs: 'URL do PACS',
};
