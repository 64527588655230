module.exports = {
    abrirMoodle: 'Acessar Moodle',
    aceitaConvidados: 'Aceita inclusão de convidados',
    acessarCurso: 'Acessar curso',
    acessoMoodle: 'Para acesso aos cursos, você será redirecionado ao ambiente Moodle.',
    adicionarGravacao: 'Adicionar gravação',
    adicionarParticipante: 'Adicionar participante',
    alertaConvidadoCadastro: 'O preenchimento de um novo convidado não caracteriza seu cadastro no sistema. Para isso, deve-se criar uma nova solicitação de cadastro no sistema.',
    alertaCursoNaoPublico: 'Cursos que não possuam a opção "Curso público" selecionada poderão ser acessadas somente por usuários que possuam ocupação dentre os CBOs selecionados abaixo.',
    alertaWebNaoPublica: 'Webconferências que não possuam a opção "Webconferência pública" selecionada poderão ser acessadas somente por usuários que possuam ocupação dentre os CBOs selecionados abaixo.',
    alteracaoCategoriaPublicoAlvoWebconferencia: 'Alteração de público-alvo',
    alteracaoCurso: 'Alteração de dados do curso',
    alteracaoSalaWebconferencia: 'Alteração da URL da sala',
    alteracaoTemaWebconferencia: 'Alteração de tema',
    alteracaoTipoAtividade: 'Alteração de tipo de atividade',
    alteracaoWebconferencia: 'Alteração de dados da webconferência',
    alterarGravacao: 'Alterar gravação',
    alterarResumo: 'Alterar resumo',
    aoVivo: 'Ao vivo',
    areaTematica: 'Área temática',
    assistirGravacao: 'Assistir gravação',
    ataWebconferencia: 'Ata da webconferência',
    avaliacao: 'Dados da avaliação',
    avaliacaoBom: 'Bom',
    avaliacaoMuitoBom: 'Muito bom',
    avaliacaoRegular: 'Regular',
    avaliacaoRuim: 'Ruim',
    avaliar: 'Avaliar',
    avisoNovoCadastro: 'Este CPF não está cadastrado como usuário. Por favor, preencha os campos abaixo para inseri-lo como convidado.',
    avisoSemEquipe: 'Caso a lista de equipes de saúde esteja vazia ou a equipe de saúde correta não esteja na lista, entre em contato com a administração para que seja feita a correção do cadastro.',
    avisoSemEquipe: 'Caso a lista de equipes de saúde esteja vazia ou a equipe de saúde correta não esteja na lista, entre em contato com a administração para que seja feita a correção do cadastro.',
    cadastroCategoriaPublicoAlvoWebconferencia: 'Cadastro de novo público-alvo',
    cadastroCurso: 'Cadastro de cursos',
    cadastroSalaWebconferencia: 'Cadastro da URL da sala',
    cadastroTemaWebconferencia: 'Cadastro de novo tema',
    cadastroTipoAtividade: 'Cadastro de novo tipo de atividade',
    cadastroWebconferencia: 'Cadastro de webconferências',
    cargaHoraria: 'Carga horária (em horas)',
    cargaHorariaMinutos: 'Carga horária (em minutos)',
    cargaHorariaMinima: 'A carga horária mínima deve ser de 1 hora',
    categoriaObrigatoria: 'Deve-se informar ao menos uma categoria.',
    categorias: 'Categorias',
    categoriasAdicionadas: 'Categorias adicionadas',
    cboObrigatorioCurso: 'Como o curso não é público, dee-se informar ao menos um CBO para a liberação do mesmo.',
    cboObrigatorioWebconferencia: 'Como a webconferência não é pública, deve-se informar ao menos um CBO para a liberação da mesma.',
    cbosLiberacao: 'CBOs para liberação da webconferência',
    cbosLiberacaoAdicionados: 'CBOs adicionados',
    cliqueParaAssistir: 'Clique para assistir',
    confirmacaoAlteracaoAvaliacao: 'Você tem certeza que deseja alterar a avaliação da webconferência?',
    confirmacaoAlteracaoCurso: 'Você deseja alterar os dados do curso?',
    confirmacaoAlteracaoMaterial: 'Você tem certeza que deseja alterar o material desta webconferência?',
    confirmacaoAlteracaoWebconferencia: 'Você deseja alterar os dados da webconferência?',
    confirmacaoAvaliacao: 'Você tem certeza que deseja salvar a avaliação informada?',
    confirmacaoCadastroCurso: 'Você deseja salvar os dados do curso?',
    confirmacaoCadastroWebconferencia: 'Você deseja salvar os dados da webconferência?',
    confirmacaoCursoNaoPublico: 'A opção "Curso público" está desmarcada, tornando este evento restrito somente aos CBOs informados. Você tem certeza que deseja salvar este curso mesmo assim?',
    confirmacaoInativacao: 'Você tem certeza que deseja inativar este evento? O mesmo não estará mais disponível para acesso e alteração.',
    confirmacaoMaterial: 'Você tem certeza que deseja enviar o material informado?',
    confirmacaoRegistroAcesso: 'Você tem certeza que deseja registrar o acesso?',
    confirmacaoRemoverItemBibliotecaAcesso: 'Você tem certeza que deseja remover este registro de acesso?',
    confirmacaoWebconferenciaNaoPublica: 'A opção "Webconferência pública" está desmarcada, tornando este evento restrito somente aos CBOs informados. Você tem certeza que deseja salvar esta webconferência mesmo assim?',
    confirmarAlteracaoCategoriaPublicoAlvo: 'Confirma a alteração do público-alvo?',
    confirmarAlteracaoSala: 'Confirma a alteraçãoda URL da sala?',
    confirmarAlteracaoTema: 'Confirma a alteração do tema?',
    nenhumaWebconferenciaAgendada: 'Nenhuma webconferência agendada',
    selecionarWebconferenciaRegistroPresenca: 'Selecionar apenas as webconferências que tenho registro de presença',
    confirmarAlteracaoTipoAtividade: 'Confirma a alteração do tipo de atividade?',
    confirmarExclusao: 'Você confirma a exclusão desta pessoa da lista de presença?',
    confirmarInsercaoCategoriaPublicoAlvo: 'Confirma a inserção do novo público-alvo?',
    confirmarInsercaoSala: 'Confirma a inserção da URL da sala?',
    confirmarInsercaoTema: 'Confirma a inserção do novo tema?',
    confirmarInsercaoTipoAtividade: 'Confirma a inserção do novo tipo de atividade?',
    contatoResponsavel: 'Contato responsável',
    conteudo: 'Conteúdo',
    convidado: 'Convidado',
    cpfParticipante: 'CPF do participante',
    criarNovaAtividade: 'Criar nova atividade',
    criarNovaAtividade: 'Criar nova atividade',
    criarNovoCurso: 'Criar novo curso',
    cursoProrrogadoInscricoesAbertas: 'Curso prorrogado \n Inscrições abertas',
    cursoProrrogadoInscricoesFechadas: 'Curso prorrogado \n Inscrições fechadas',
    cursoEmAndamentoInscricoesAbertas: 'Curso em andamento \n Inscrições abertas',
    cursoEmAndamentoInscricoesFechadas: 'Curso em andamento \n Inscrições fechadas',
    cursoEncerradoInscricoesFechadas: 'Curso encerrado',
    cursoNaoIniciadoInscricoesAbertas: 'Curso não iniciado \n Inscrições abertas',
    cursoNaoIniciadoInscricoesFechadas: 'Curso não iniciado \n Inscrições fechadas',
    cursoNaoIniciadoVagasEsgotadas: 'Curso não iniciado \n Vagas esgotadas',
    cursoEmAndamentoVagasEsgotadas: 'Curso em andamento \n Vagas esgotadas',
    cursoEncerradoVagasEsgotadas: 'Curso encerrado \n Vagas esgotadas',
    cursoPublico: 'Curso público',
    cursos: 'Cursos',
    dadosAtividade: 'Dados das atividades',
    dadosDecs: 'Descritores',
    dadosInscricoes: 'Dados das inscrições',
    dadosPalestrante: 'Dados do palestrante',
    dataFinalCurso: 'Data final do curso',
    dataFinalInscricao: 'Data final da inscrição',
    dataFinalInscricaoAntesDataFinal: 'A data final da inscrição deve acontecer até o término do curso',
    dataGravacao: 'Data da gravação',
    dataInicialCurso: 'Data inicial do curso',
    dataInicialInscricao: 'Data inicial da inscrição',
    dataInicialInscricaoAntesDataInicial: 'A data inicial da inscrição deve acontecer antes da data inicial do curso',
    dataInscricaoAnteriorDataInicial: 'A data inicial de inscrição deve ser anterior a data inicial do curso',
    dataMostrarCurso: 'Data para mostrar o curso',
    dataMostrarCursoAnteriorDataInicial: 'A data para mostrar o curso deve ser anterior a data inicial do curso',
    dataOcultarCurso: 'Data para ocultar o curso',
    dataOcultarCursoPosteriorDataFinal: 'A data para ocultar o curso deve ser posterior a data final (contando prorrogação)',
    decsMin1Descritor: 'Deve-se informar ao menos um descritor',
    descricaoInvalidaMin: 'A descrição deve ter no mínimo 10 caractéres',
    descricaoMaterial: 'Descricao',
    detalhes: 'Detalhes',
    detalhesCurso: 'Detalhes do curso',
    enderecoCurso: 'Endereço do curso',
    enderecoSala: 'Endereço da sala',
    erroDataPassada: 'Data não pode ser anterior à data do evento',
    erroGenericoInativar: 'Ocorreu um erro ao desativar a webconferência. Tente novamente mais tarde.',
    erroImagemCurso: 'A imagem do curso deve ser quadrada e possuir até 800x800 pixels',
    erroImagemWebconferencia: 'A imagem da webconferência deve ser quadrada',
    erroNomePalestranteMax: 'O nome do palestrante deve conter no máximo 100 caractéres',
    erroTituloMax200Caracteres: 'O título deve ter no máximo 200 caractéres',
    escolhaEquipe: 'Escolha uma equipe de saúde',
    escolhaInstituicaoEquipe: 'Escolha de instituição e equipe',
    especialidadeArea: 'Especialidade ou área',
    excluindoParticipante: 'Excluindo participante...',
    filtroCbosLiberacao: 'Filtro de CBOs para liberação de webconferência',
    filtroPublicoAlvo: 'Filtro de públicos-alvo',
    fimCurso: 'Fim do curso',
    formatoAtividade: 'Formato da atividade',
    formatoInstrucao: 'Formato da instrução',
    formatosArquivoCursos: 'Arquivo (.jpg, .jpeg, .png e .pdf)',
    fotoWebconferencia: 'Imagem da webconferência',
    gravacao: 'Gravação',
    gravacao: 'Gravação',
    horario: 'Horário',
    horario: 'Horário',
    imagemCurso: 'Imagem do curso',
    inclusaoParticipante: 'Inclusão de participante',
    informacoesPalestrantes: 'Informações sobre palestrantes',
    inicioCurso: 'Início do curso',
    instituicaoSemSala: 'A instituição selecionada não possui sala cadastrada.',
    instrucaoCategoriasPublicoAlvo: `<span>- Pesquisa por categorias de públicos-alvo de webconferência.</span>
                <span>- A partir dessa tela, é possível o cadastro de categorias de públicos-alvo que serão utilizadas na criaçao de um novo evento.</span>
                <span>- A partir dessa tela, também é possível alterar categorias anteriormente cadastradas e inativá-las.</span>`,
    instrucaoCbosLiberacaoCurso: 'O campo abaixo serve somente como um filtro para pesquisa de CBOs. Na inserção do novo curso, vale o que está selecionado no quadro abaixo do filtro.',
    instrucaoCbosLiberacaoWebconferencia: 'O campo abaixo serve somente como um filtro para pesquisa de CBOs. Na inserção da nova webconferência, vale o que está selecionado no quadro abaixo do filtro.',
    instrucaoListaPresenca: 'Para registrar a presença de um participante, preencha o(s) campo(s) abaixo:',
    instrucaoPublicoAlvo: 'O campo abaixo serve somente como um filtro para pesquisa de públicos-alvo. Na inserção da nova webconferência, vale o que está selecionado no quadro abaixo do filtro.',
    instrucaoSemGravacao: 'Até o momento não há gravação disponível para esta webconfêrencia.',
    instrucaoTemas: `<span>- Pesquisa por temas de materiais de webconferência.</span>
                <span>- A partir dessa tela, é possível o cadastro de temas que serão utilizados no envio de materiais de webconferência.</span>
                <span>- A partir dessa tela, também é possível alterar temas anteriormente cadastrados e inativá-los.</span>`,
    instrucaoTipoAtividade: `<span>- Pesquisa de tipos de atividade.</span>
                <span>- A partir dessa tela, é possível o cadastro de tipos de atividade que serão utilizadas na criaçao de um novo evento.</span>
                <span>- A partir dessa tela, também é possível alterar tipos de atividade anteriormente cadastradas e inativá-las.</span>`,
    listaEquipesParticipante: 'Lista de equipes participantes',
    listaPresenca: 'Lista de presença',
    logoParceiros: 'Logos dos parceiros',
    maisInfo: 'Mais info',
    materiais: 'Materiais',
    mensagemAjudaCurso: {
        acessoLiberadoAvaliacaoEmissaoCertificado: 'Acesso liberado para avaliação do minicurso e emissão do certificado',
        dataConclusaoProrrogada: data => `A data de conclusão do curso foi prorrogada para ${data}`,
        inicioMinicurso: data => `Início do minicurso ${data}`,
        inscricoesAbertasAte: data => `Inscrições abertas até ${data}`,
        inscricoesEncerradas: 'Inscrições encerradas'
    },
    mensagemSemAta: 'Esta webconferência ainda não possui ata',
    // mensagemSemAvaliacao: 'Esta webconferência ainda não foi avaliada',
    mensagemSemAvaliacao: 'Você deve preencher a avaliação antes de enviá-la.',
    // mensagemSemMaterial: 'Esta webconferência não possui materiais',
    mensagemSemMaterial: 'Você deve preencher o material antes de enviá-lo.',
    mensagemStatusCurso: {
        cursoProrrogado: 'Curso prorrogado',
        cursoAndamento: 'Curso em andamento',
        cursoEncerrado: 'Curso encerrado',
        cursoNaoIniciado: 'Curso não iniciado',
        inscricaoAPartirDe: data => `Inscreva-se a partir de ${data}`,
        inscricoesAbertas: 'Inscrições abertas!',
        inscricoesFechadas: 'Inscrições fechadas! ',
        vagasEsgotadas: 'Vagas esgotadas'
    },
    mensagemSucessoAlteracao: 'Tipo de atividade alterado com sucesso',
    mensagemSucessoAlteracaoAtividade: 'Tipo de atividade alterado com sucesso',
    mensagemSucessoAlteracaoPublicoAlvo: 'Público-alvo alterado com sucesso',
    mensagemSucessoAlteracaoSala: 'URL da sala alterada com sucesso',
    mensagemSucessoAlteracaoTema: 'Tema alterado com sucesso',
    mensagemSucessoInativacao: 'Webconferência inativada com sucesso',
    mensagemSucessoInsercao: 'Tipo de atividade inserido com sucesso',
    mensagemSucessoInsercaoAtividade: 'Tipo de atividade inserido com sucesso',
    mensagemSucessoInsercaoPublicoAlvo: 'Público-alvo inserido com sucesso',
    mensagemSucessoInsercaoSala: 'URL da sala inserida com sucesso',
    mensagemSucessoInsercaoTema: 'Tema inserido com sucesso',
    moodle: 'Moodle',
    mostrarCursoAPartir: 'Mostrar curso a partir de',
    mostrarCursoAte: 'Mostrar o curso até',
    nomePersonalizadoInstancia: 'Nome personalizado da instância',
    novaPergunta: 'Nova pergunta',
    numeroAcompanhantes: 'Número de acompanhantes',
    numeroTotalAcompanhantes: 'Número total de acompanhantes',
    palestrante: 'Palestrante',
    papelAtribuidoPadrao: 'Papel atribuído por padrão',
    participante: 'Participante',
    participantes: 'Participantes',
    pergunta: 'Pergunta',
    perguntaNaoRespondida: 'Pergunta não respondida',
    perguntas: 'Perguntas',
    periodoInscricao: 'Período de inscrição',
    periodoMinicurso: 'Período do curso',
    permitirAlunoCanceleInscricao: 'Permitir o cancelamento da inscrição',
    permitirAutoInscricao: 'Permitir auto-inscrição',
    permitirInscricoesExistentes: 'Permitir inscrições existentes',
    permitirNovasInscricoes: 'Permitir novas inscrições',
    placeholderDescricao: 'Informe a descrição do curso',
    placeholderSumario: 'Informe o sumário do curso',
    prorrogacao: 'Prorrogação (em dias)',
    proximasWebs: 'Próximas webs',
    publica: 'Pública',
    publicoAlvo: 'Públicos-alvo',
    publicoAlvoObrigatorio: 'O público-alvo é obrigatório',
    erroSelecaoPublicoAlvo: 'Deve-se informar no mínimo 1 e no máximo 4 públicos-alvo.',
    publicosAlvoAdicionados: 'Públicos-alvo adicionados',
    registrar: 'Registrar',
    registrarAcesso: 'Registrar acesso',
    registroAcessoRemovido: 'Registro de acesso removido.',
    registrosAcesso: 'Registros de acesso',
    resposta: 'Resposta',
    respostas: 'Respostas',
    resumo: 'Resumo',
    resumoCurso: 'Resumo do curso',
    resumoWebconferencia: 'Resumo de webconferência',
    salas: 'Salas',
    seguirWebconferencia: 'Seguir para a webconferência',
    selecioneMoodle: 'Selecione um ambiente Moodle',
    semEquipeNemInstituicao: 'Você não está cadastrado em nenhuma equipe de saúde nem instituição elegível para participar da lista de presença desta webconferência. Por favor, entre em contato com a administração para que seja feita a correção do cadastro.',
    semInstituicaoInformada: 'Sem instituição informada',
    sobrePalestrante: 'Sobre o palestrante',
    sucessoEscolhaEquipe: 'Equipe de saúde registrada com sucesso.',
    sucessoExcluirPresenca: 'Participante excluído com sucesso.',
    sucessoRegistroParticipante: 'Participante adicionado com sucesso.',
    sucessoRegistroPergunta: 'Pergunta inserida com sucesso.',
    sucessoRegistroResumo: 'Resumo registrado com sucesso.',
    sumarioInvalidaMin: 'O sumário deve ter no mínimo 10 caractéres',
    tema: 'Tema',
    temas: 'Temas',
    tenhoInteresse: 'Tenho interesse',
    terminoCurso: 'Término do curso',
    textoPublicoAlvo: 'Utilize este campo para filtrar públicos alvo',
    tipoAtividade: 'Tipo de atividade',
    tipoAtividade: 'Tipos de atividade',
    tituloAvaliacaoClarezaObjetividade: 'Como você(s) avaliam a webconferência considerando sua clareza e objetividade?',
    tituloAvaliacaoClassificacao: 'Como você(s) classifica(m) a webconferência de hoje?',
    tituloAvaliacaoRelevancia: 'Você(s) considera(s) que o tema apresentado nesta webconferência tem relevância em seu(s) processo(s) de trabalho ou sua(s) prática(s) diária(s)?',
    tituloBreve: 'Título breve',
    tituloDetalhesPagina: 'Detalhes da webconferência',
    tituloInvalidoMin: 'O título deve ter no mínimo 10 caractéres',
    tituloMinicurso: 'Título do minicurso',
    tituloModalAcessoMoodle: 'Acesso ao Moodle',
    tituloModalAvaliacao: 'Avaliação',
    tituloModalMaterial: 'Material da webconferência',
    tituloPagina: 'Pesquisa de webconferências',
    tituloPaginaPesquisaWebconferencias: 'Pesquisa de webconferências',
    url: 'URL',
    vagasEsgotadas: 'Vagas esgotadas',
    verificarMesmaInstituicaoUsuarioInclusao: 'A instituição do convidado é esta:',
    webconferencia: 'Webconferência',
    webconferenciaPublica: 'Webconferência pública',
    webconferencias: 'Webconferências',
    websGravadas: 'Webs gravadas',
    limitePublicosAlvo: 'Deve-se informar no máximo 4 públicos-alvo distintos.',
    websMaisUmaPagina: totalWebs => `Existem ${totalWebs} wenconferências para visualização. Use as setas laterais para ver mais.`,
    agendaWebconferencias: 'Agenda de webconferências',
    acervoWebconferencias: 'Acervo de webconferências'
};