const PESQUISA = {
    PAGINACAO_DEFAULT: 10,
    PAGINACAO_MINIMA: 1,
    START_MINIMO: 0,
    SEM_RESULTADO: 0,
    MENSAGEM_SEM_RESULTADO: 'Nenhum resultado encontrado'
};

const PERFIL = {
    MEDICO_SOLICITANTE: 'medicoSolicitante',
    MEDICO_LAUDADOR: 'medicoLaudador',
    MEDICO_RESIDENTE: 'medicoResidente',
    MEDICO_EXECUTOR: 'medicoExecutor',
    ADMINISTRADOR: 'administrador',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
    ADMINISTRADOR_LOCAL: 'adminLocal',
    TECNICO: 'tecnico',
    REGULADOR_EXAME: 'reguladorExame',
    VISUALIZADOR: 'visualizador',
    VISUALIZADOR_REDE: 'visualizadorRede',
};

const TIPO_CONTATO = {
    TELEFONE_RESIDENCIAL: 1,
    TELEFONE_CELULAR: 2,
    TELEFONE_COMERCIAL: 3,
    EMAIL: 4,
    FAX: 5,
    OUTRO: 6
}

const MODULOS_EXAME = {
    DERMATOLOGIA: {
        SIGLA: 'VLP',
        DESCRICAO: 'TELEDERMATO'
    },
    ELETROCARDIOGRAFIA: {
        SIGLA: 'ECG',
        DESCRICAO: 'ELETROCARDIOGRAFIA'
    }
}

const LOG = {
    EMERG: 0,
    ALERT: 1,
    CRIT: 2,
    ERR: 3,
    WARN: 4,
    NOTICE: 5,
    INFO: 6,
    DEBUG: 7,

    // ECG
    ECG_SORTEAR_PROXIMO_EXAME_LAUDO: 'ECG_SORTEAR_PROXIMO_EXAME_LAUDO',

    // POLISSONOGRAFIA
    POLISSONOGRAFIA_SOLICITAR: 'POLISSONOGRAFIA_SOLICITAR',
    POLISSONOGRAFIA_ERRO_SOLICITAR: 'POLISSONOGRAFIA_ERRO_SOLICITAR',

    // EXAMES
    ACESSO_EXAME_PROTOCOLO: 'STT_WEB_ACESSO_EXAME_PROTOCOLO',
    ACESSO_EXAME_INTERNO: 'STT_WEB_ACESSO_EXAME_INTERNO',
    ENVIO_EXAME_INTERNO: 'STT_WEB_ENVIO_EXAME_INTERNO',
    LOGIN: 'STT_WEB_LOGIN',
    LOGIN_ERRO: 'STT_WEB_LOGIN_ERRO',
    LOGIN_INATIVO: 'STT_WEB_LOGIN_INATIVO',
    TERMO_USO_ACEITO: 'STT2_TERMO_USO_ACEITO',
    WHATS_APP_GROUP: 'STT2_MENSAGEM_RECEBIDA',
    ATUALIZACAO_AUTORIZACAO_DIVULGACAO: 'STT2_ATUALIZACAO_AUTORIZACAO_DIVULGACAO',
    INTEGRACAO_LAUDO: 'INTEGRACAO_LAUDO',
    CARIMBO_TEMPO: 'STT_WEB_CARIMBO_TEMPO',
    ASSINATURA_DIGITAL: 'STT_WEB_ASSINATURA_DIGITAL',
    SORTEIO_EXAME: 'STT_WEB_SORTEIO_EXAME',
    EMISSAO_LAUDO: 'STT_WEB_EMISSAO_LAUDO',
    ERRO_EMISSAO_LAUDO: 'STT_WEB_ERRO_EMISSAO_LAUDO',
    ERRO_SERVICOS_MONITORADOS: 'STT_WEB_ERRO_SERVICOS_MONITORADOS',
    EXAME_INVALIDADO: 'STT_WEB_EXAME_INVALIDADO',
    PACIENTE_VINCULADO_EXAME: 'STT_WEB_PACIENTE_VINCULADO_EXAME',
    EXAME_PRIORIZADO: 'STT_WEB_EXAME_PRIORIZADO',
    EXAME_IGNORADO: 'STT_WEB_EXAME_IGNORADO',
    ERRO_NOTIFICACAO_WS: 'ERRO_NOTIFICACAO_WS',
    REDE_TELEMEDICINA_ALTERADA: 'REDE_TELEMEDICINA_ALTERADA',


    //Tratamento fora de domicílio
    DEVOLUCAO_TFD: 'STT2_WEB_DEVOLUCAO_TFD',
    ERRO_DEVOLUCAO_TFD: 'STT2_WEB_ERRO_DEVOLUCAO_TFD',
    CANCELAMENTO_TFD: 'STT2_WEB_CANCELAMENTO_TFD',
    ERRO_CANCELAMENTO_TFD: 'STT2_WEB_ERRO_CANCELAMENTO_TFD',
    NEGACAO_TFD: 'STT2_WEB_NEGACAO_TFD',
    ERRO_NEGACAO_TFD: 'STT2_WEB_ERRO_NEGACAO_TFD',
    ARQUIVAMENTO_TFD: 'STT2_WEB_ARQUIVAMENTO_TFD',
    ERRO_ARQUIVAMENTO_TFD: 'STT2_WEB_ERRO_ARQUIVAMENTO_TFD',
    ENCAMINHAMENTO_TFD: 'STT2_WEB_ENCAMINHAMENTO_TFD',
    ERRO_ENCAMINHAMENTO_TFD: 'STT2_WEB_ERRO_ENCAMINHAMENTO_TFD',
    CONFIRMAR_AG_CNRAC_TFD: 'STT2_WEB_CONFIRMAR_AG_CNRAC_TFD',
    ERRO_CONFIRMAR_AG_CNRAC_TFD: 'STT2_WEB_ERRO_CONFIRMAR_AG_CNRAC_TFD',
    CONFIRMAR_AGUARDO_AG_TFD: 'STT2_WEB_CONFIRMAR_AGUARDO_AG_TFD',
    ERRO_CONFIRMAR_AGUARDO_AG_TFD: 'STT2_WEB_ERRO_CONFIRMAR_AGUARDO_AG_TFD',
    CONFIRMAR_AG_TFD: 'STT2_WEB_CONFIRMAR_AG_TFD',
    ERRO_CONFIRMAR_AG_TFD: 'STT2_WEB_ERRO_CONFIRMAR_AG_TFD',
    AUTORIZAR_TFD: 'STT2_WEB_AUTORIZAR_TFD',
    ERRO_AUTORIZAR_TFD: 'STT2_WEB_ERRO_AUTORIZAR_TFD',
    ENCAMINHAR_REGULADOR_TFD: 'STT2_WEB_ENCAMINHAR_REGULADOR_TFD',
    ERRO_ENCAMINHAR_REGULADOR_TFD: 'STT2_WEB_ERRO_ENCAMINHAR_REGULADOR_TFD',
    ENCAMINHAR_AVALIADOR_TFD: 'STT2_WEB_ENCAMINHAR_AVALIADOR_TFD',
    ERRO_ENCAMINHAR_AVALIADOR_TFD: 'STT2_WEB_ERRO_ENCAMINHAR_AVALIADOR_TFD',
    INFORMAR_CNRAC_TFD: 'STT2_WEB_INFORMAR_CNRAC_TFD',
    ERRO_INFORMAR_CNRAC_TFD: 'STT2_WEB_ERRO_INFORMAR_CNRAC_TFD',
    INATIVAR_COMP_COMPARECIMENTO_TFD: 'STT2_WEB_INATIVAR_COMP_COMPARECIMENTO_TFD',
    ERRO_INATIVAR_COMP_COMPARECIMENTO_TFD: 'STT2_WEB_ERRO_INATIVAR_COMP_COMPARECIMENTO_TFD',
    INFORMAR_PROCEDIMENTO_SOLICITADO_TFD: 'STT2_WEB_INFORMAR_PROCEDIMENTO_SOLICITADO_TFD',
    ERRO_INFORMAR_PROCEDIMENTO_SOLICITADO_TFD: 'STT2_WEB_ERRO_INFORMAR_PROCEDIMENTO_SOLICITADO_TFD',
    REGULAR_TFD: 'STT2_WEB_REGULAR_TFD',
    ERRO_REGULAR_TFD: 'STT2_WEB_ERRO_REGULAR_TFD',
    VERIFICAR_CARIMBO_TEMPO_TFD: 'STT2_WEB_VERIFICAR_CARIMBO_TEMPO_TFD',
    ERRO_VERIFICAR_CARIMBO_TEMPO_TFD: 'STT2_WEB_ERRO_VERIFICAR_CARIMBO_TEMPO_TFD',
    INICIAR_NOVO_TFD: 'STT2_WEB_INICIAR_NOVO_TFD',
    ERRO_INICIAR_NOVO_TFD: 'STT2_WEB_ERRO_INICIAR_NOVO_TFD',
    EDITAR_TFD: 'STT2_WEB_EDITAR_TFD',
    ERRO_EDITAR_TFD: 'STT2_WEB_ERRO_EDITAR_TFD',
    LISTAR_SOLICITACOES_TFD: 'STT2_WEB_LISTAR_SOLICITACOES_TFD',
    ERRO_LISTAR_SOLICITACOES_TFD: 'STT2_WEB_ERRO_LISTAR_SOLICITACOES_TFD',
    PESQUISAR_SOLICITACAO_TFD: 'STT2_WEB_PESQUISAR_SOLICITACAO_TFD',
    ERRO_PESQUISAR_SOLICITACAO_TFD: 'STT2_WEB_ERRO_PESQUISAR_SOLICITACAO_TFD',
    BAIXAR_ARQUIVO_TFD: 'STT2_WEB_BAIXAR_ARQUIVO_TFD',
    ERRO_BAIXAR_ARQUIVO_TFD: 'STT2_WEB_ERRO_BAIXAR_ARQUIVO_TFD',
    BAIXAR_COMPROVANTE_COMPARECIMENDO_TFD: 'STT2_WEB_BAIXAR_COMPROVANTE_COMPARECIMENDO_TFD',
    ERRO_BAIXAR_COMPROVANTE_COMPARECIMENDO_TFD: 'STT2_WEB_ERRO_BAIXAR_COMPROVANTE_COMPARECIMENDO_TFD',
    IMPRIMIR_TFD: 'STT2_WEB_IMPRIMIR_TFD',
    ERRO_IMPRIMIR_TFD: 'STT2_WEB_ERRO_IMPRIMIR_TFD',
    ERRO_PESQUISA_CIDADES_ORIGEM_TFD: 'STT2_WEB_ERRO_PESQUISA_CIDADES_ORIGEM_TFD',
    ERRO_PESQUISA_TRANSPORTES_RECOMENDADOS_TFD: 'STT2_WEB_ERRO_PESQUISA_TRANSPORTES_RECOMENDADOS_TFD',
    ERRO_PESQUISA_CIDADES_AGENDADORAS_TFD: 'STT2_WEB_ERRO_PESQUISA_CIDADES_AGENDADORAS_TFD',
    ERRO_PESQUISA_CIDADES_AGENDADORAS_SOLICITANTE_TFD: 'STT2_WEB_ERRO_PESQUISA_CIDADES_AGENDADORAS_SOLICITANTE_TFD',
    ERRO_PESQUISA_TIPOS_ANEXO_TFD: 'STT2_WEB_ERRO_PESQUISA_TIPOS_ANEXO_TFD',
    ERRO_PESQUISA_PROCEDIMENTOS_SOLICITADOS_TFD: 'STT2_WEB_ERRO_PESQUISA_PROCEDIMENTOS_SOLICITADOS_TFD',
    ERRO_INICIAR_SOLICITACAO_TFD: 'STT2_WEB_ERRO_INICIAR_SOLICITACAO_TFD',
    ERRO_ENCAMINHAR_SOLICITACAO_TFD: 'STT2_WEB_ERRO_ENCAMINHAR_SOLICITACAO_TFD',
    ERRO_EDITAR_SOLICITACAO_TFD: 'STT2_WEB_ERRO_EDITAR_SOLICITACAO_TFD',
    ERRO_PESQUISA_MOTIVOS_DEVOLUCAO_TFD: 'STT2_WEB_ERRO_PESQUISA_MOTIVOS_DEVOLUCAO_TFD',
    ERRO_PESQUISA_MOTIVOS_CANCELAMENTO: 'STT2_WEB_ERRO_PESQUISA_MOTIVOS_CANCELAMENTO',
    ERRO_PESQUISA_AGENDAMENTO_TFD: 'STT2_WEB_ERRO_PESQUISA_AGENDAMENTO_TFD',
    ERRO_PESQUISA_DADOS_REGULADOR_TFD: 'ERRO_PESQUISA_DADOS_REGULADOR_TFD',
    ERRO_PESQUISA_DADOS_AVALIADOR_TFD: 'ERRO_PESQUISA_DADOS_AVALIADOR_TFD',
    ERRO_PESQUISA_TRANSPORTE_ID_TFD: 'STT2_WEB_ERRO_PESQUISA_TRANSPORTE_ID_TFD',
    ERRO_ANEXAR_COMPROVANTE_COMPARECIMENTO_TFD: 'STT2_WEB_ERRO_ANEXAR_COMPROVANTE_COMPARECIMENTO_TFD',
    ANEXAR_COMPROVANTE_COMPARECIMENTO_TFD: 'STT2_WEB_ANEXAR_COMPROVANTE_COMPARECIMENTO_TFD',
    TFD_PASSAGENS_PESQUISA: 'STT2_WEB_TFD_PASSAGENS_PESQUISA',
    TFD_PASSAGENS_ERRO_PESQUISA: 'STT2_WEB_TFD_PASSAGENS_ERRO_PESQUISA',
    TFD_PASSAGENS_IMPRESSAO: 'STT2_WEB_TFD_PASSAGENS_IMPRESSAO',
    TFD_PASSAGENS_ERRO_IMPRESSAO: 'STT2_WEB_TFD_PASSAGENS_ERRO_IMPRESSAO',
    TFD_PASSAGENS_PESQUISA_VINCULO: 'STT2_WEB_TFD_PASSAGENS_PESQUISA_VINCULO',
    TFD_PASSAGENS_ERRO_PESQUISA_VINCULO: 'STT2_WEB_TFD_PASSAGENS_ERRO_PESQUISA_VINCULO',
    TFD_PASSAGENS_PESQUISA_ANEXO: 'STT2_WEB_TFD_PASSAGENS_PESQUISA_ANEXO',
    TFD_PASSAGENS_ERRO_PESQUISA_ANEXO: 'STT2_WEB_TFD_PASSAGENS_ERRO_PESQUISA_ANEXO',
    TFD_PASSAGENS_PESQUISA_ACOMPANHANTE: 'STT2_WEB_TFD_PASSAGENS_PESQUISA_ACOMPANHANTE',
    TFD_PASSAGENS_ERRO_PESQUISA_ACOMPANHANTE: 'STT2_WEB_TFD_PASSAGENS_ERRO_PESQUISA_ACOMPANHANTE',
    TFD_PASSAGENS_VISUALIZACAO: 'STT2_WEB_TFD_PASSAGENS_VISUALIZACAO',
    TFD_PASSAGENS_ERRO_VISUALIZACAO: 'STT2_WEB_TFD_PASSAGENS_ERRO_VISUALIZACAO',
    TFD_PASSAGENS_PESQUISA_RASCUNHOS_ID_TFD: 'STT2_WEB_TFD_PASSAGENS_PESQUISA_RASCUNHOS_ID_TFD',
    TFD_PASSAGENS_ERRO_PESQUISA_RASCUNHOS_ID_TFD: 'STT2_WEB_TFD_PASSAGENS_ERRO_PESQUISA_RASCUNHOS_ID_TFD',
    TFD_PASSAGENS_ABERTURA: 'STT2_WEB_TFD_PASSAGENS_ABERTURA',
    TFD_PASSAGENS_ERRO_ABERTURA: 'STT2_WEB_TFD_PASSAGENS_ERRO_ABERTURA',
    TFD_PASSAGENS_ABRIR_ALTERACAO: 'STT2_WEB_TFD_PASSAGENS_ABRIR_ALTERACAO',
    TFD_PASSAGENS_ERRO_ABRIR_ALTERACAO: 'STT2_WEB_TFD_PASSAGENS_ERRO_ABRIR_ALTERACAO',
    TFD_PASSAGENS_NOVA_SOLICITACAO: 'STT2_WEB_TFD_PASSAGENS_NOVA_SOLICITACAO',
    TFD_PASSAGENS_ERRO_NOVA_SOLICITACAO: 'STT2_WEB_TFD_PASSAGENS_ERRO_NOVA_SOLICITACAO',
    TFD_PASSAGENS_PROCESSO_ESPELHO: 'STT2_WEB_TFD_PASSAGENS_PROCESSO_ESPELHO',
    TFD_PASSAGENS_ERRO_PROCESSO_ESPELHO: 'STT2_WEB_TFD_PASSAGENS_ERRO_PROCESSO_ESPELHO',
    TFD_PASSAGENS_SALVAR_PROCESSO: 'STT2_WEB_TFD_PASSAGENS_SALVAR_PROCESSO',
    TFD_PASSAGENS_ERRO_SALVAR_PROCESSO: 'STT2_WEB_TFD_PASSAGENS_ERRO_SALVAR_PROCESSO',
    TFD_PASSAGENS_OP_VOLTAR: 'STT2_WEB_TFD_PASSAGENS_OP_VOLTAR',
    TFD_PASSAGENS_ERRO_OP_VOLTAR: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_VOLTAR',
    TFD_PASSAGENS_OP_REABRIR: 'STT2_WEB_TFD_PASSAGENS_OP_REABRIR',
    TFD_PASSAGENS_ERRO_OP_REABRIR: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_REABRIR',
    TFD_PASSAGENS_OP_DEVOLVER: 'STT2_WEB_TFD_PASSAGENS_OP_DEVOLVER',
    TFD_PASSAGENS_ERRO_OP_DEVOLVER: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_DEVOLVER',
    TFD_PASSAGENS_OP_SUSPENDER: 'STT2_WEB_TFD_PASSAGENS_OP_SUSPENDER',
    TFD_PASSAGENS_ERRO_OP_SUSPENDER: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_SUSPENDER',
    TFD_PASSAGENS_OP_CANCELAR: 'STT2_WEB_TFD_PASSAGENS_OP_CANCELAR',
    TFD_PASSAGENS_ERRO_OP_CANCELAR: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_CANCELAR',
    TFD_PASSAGENS_OP_SEL_MOTORISTA: 'STT2_WEB_TFD_PASSAGENS_OP_SEL_MOTORISTA',
    TFD_PASSAGENS_ERRO_OP_SEL_MOTORISTA: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_SEL_MOTORISTA',
    TFD_PASSAGENS_OP_SEL_REGULADOR: 'STT2_WEB_TFD_PASSAGENS_OP_SEL_REGULADOR',
    TFD_PASSAGENS_ERRO_OP_SEL_REGULADOR: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_SEL_REGULADOR',
    TFD_PASSAGENS_OP_APROVAR: 'STT2_WEB_TFD_PASSAGENS_OP_APROVAR',
    TFD_PASSAGENS_ERRO_OP_APROVAR: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_APROVAR',
    TFD_PASSAGENS_OP_AUTORIZAR: 'STT2_WEB_TFD_PASSAGENS_OP_AUTORIZAR',
    TFD_PASSAGENS_ERRO_OP_AUTORIZAR: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_AUTORIZAR',
    TFD_PASSAGENS_OP_ARQUIVAR: 'STT2_WEB_TFD_PASSAGENS_OP_ARQUIVAR',
    TFD_PASSAGENS_ERRO_OP_ARQUIVAR: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_ARQUIVAR',
    TFD_PASSAGENS_OP_ANEXAR_COMPROVANTE: 'STT2_WEB_TFD_PASSAGENS_OP_ANEXAR_COMPROVANTE',
    TFD_PASSAGENS_ERRO_OP_ANEXAR_COMPROVANTE: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_ANEXAR_COMPROVANTE',
    TFD_PASSAGENS_OP_ENCAMINHAR_VIAGEM: 'STT2_WEB_TFD_PASSAGENS_OP_ENCAMINHAR_VIAGEM',
    TFD_PASSAGENS_ERRO_OP_ENCAMINHAR_VIAGEM: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_ENCAMINHAR_VIAGEM',
    TFD_PASSAGENS_ERRO_OP_CADASTRO_MOTORISTA: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_CADASTRO_MOTORISTA',
    TFD_PASSAGENS_OP_CADASTRO_MOTORISTA: 'STT2_WEB_TFD_PASSAGENS_OP_CADASTRO_MOTORISTA',
    TFD_PASSAGENS_ERRO_OP_CADASTRO_MOTIVO_CANCELAMENTO: 'STT2_WEB_TFD_PASSAGENS_ERRO_OP_CADASTRO_MOT_CANCEL',
    TFD_PASSAGENS_OP_CADASTRO_MOTIVO_CANCELAMENTO: 'STT2_WEB_TFD_PASSAGENS_OP_CADASTRO_MOT_CANCEL',

    // Itinerário terapêutico
    STT_WEB_ACESSO_EXAME_IT: 'STT_WEB_ACESSO_EXAME_IT',
    STT_WEB_AVALIACAO_EXAME_IT: 'STT_WEB_AVALIACAO_EXAME_IT',


    //ADMINISTRATIVO

    //Pesquisa de funcionários
    ADMINISTRATIVO_PESQUISA_FUNCIONARIOS: 'ADMINISTRATIVO_PESQUISA_FUNCIONARIOS',
    ADMINISTRATIVO_ERRO_PESQUISA_FUNCIONARIOS: 'ADMINISTRATIVO_ERRO_PESQUISA_FUNCIONARIOS',
    ADMINISTRATIVO_PESQUISA_FUNCIONARIO: 'ADMINISTRATIVO_PESQUISA_FUNCIONARIO',
    ADMINISTRATIVO_ERRO_PESQUISA_FUNCIONARIO: 'ADMINISTRATIVO_ERRO_PESQUISA_FUNCIONARIO',
    ADMINISTRATIVO_ERRO_PESQUISA_INSTITUICOES_ADMIN_LOCAL: 'ADMINISTRATIVO_ERRO_PESQUISA_INSTITUICOES_ADMIN_LOCAL',
    ADMINISTRATIVO_CADASTRAR_FUNCIONARIO: 'ADMINISTRATIVO_CADASTRAR_FUNCIONARIO',
    ADMINISTRATIVO_ALTERAR_AUTORIZACAO_DIVULGACAO_FUNC: 'ADMINISTRATIVO_ALTERAR_AUTORIZACAO_DIVULGACAO_FUNC',
    ADMINISTRATIVO_LIMPEZA_CACHE_USUARIO_ERRO: 'ADMINISTRATIVO_LIMPEZA_CACHE_USUARIO_ERRO',
    ADMINISTRATIVO_CADASTRAR_PERFIL: 'ADMINISTRATIVO_CADASTRAR_PERFIL',
    ADMINISTRATIVO_ERRO_CADASTRAR_FUNCIONARIO: 'ADMINISTRATIVO_ERRO_CADASTRAR_FUNCIONARIO',
    ADMINISTRATIVO_EXCLUIR_PERFIL: 'ADMINISTRATIVO_EXCLUIR_PERFIL',
    ADMINISTRATIVO_ERRO_EXCLUIR_PERFIL: 'ADMINISTRATIVO_ERRO_EXCLUIR_PERFIL',
    ADMINISTRATIVO_ERRO_CADASTRAR_PERFIL: 'ADMINISTRATIVO_ERRO_CADASTRAR_PERFIL',
    ADMINISTRATIVO_PESQUISA_FUNCIONARIO_CADSUS: 'ADMINISTRATIVO_PESQERRO_UISA_FUNCIONARIO_CADSUS',
    ADMINISTRATIVO_ERRO_PESQUISA_FUNCIONARIO_CADSUS: 'ADMINISTRATIVO_PESQUISA_FUNCIONARIO_CADSUS',
    ADMINISTRATIVO_ALTERACAO_SENHA_USUARIO: 'ADMINISTRATIVO_ALTERACAO_SENHA_USUARIO',
    ADMINISTRATIVO_ERRO_ALTERACAO_SENHA_USUARIO: 'ADMINISTRATIVO_ERRO_ALTERACAO_SENHA_USUARIO',
    ADMINISTRATIVO_ERRO_VERIFICACAO_LOGIN_DUPLICADO: 'ADMINISTRATIVO_ERRO_VERIFICACAO_LOGIN_DUPLICADO',
    ADMINISTRATIVO_ERRO_SALVAR_LOG_ALTERACAO_DADOS: 'ADMINISTRATIVO_ERRO_SALVAR_LOG_ALTERACAO_DADOS',
    ADMINISTRATIVO_ERRO_PESQUISA_PERFIS_SUPERIORES: 'ADMINISTRATIVO_ERRO_PESQUISA_PERFIS_SUPERIORES',

    ADMINISTRATIVO_CADASTRAR_CIDADE_REFRENCIA: 'ADMINISTRATIVO_CADASTRAR_CIDADE_REFRENCIA',
    ADMINISTRATIVO_ERRO_CADASTRAR_CIDADE_REFRENCIA: 'ADMINISTRATIVO_ERRO_CADASTRAR_CIDADE_REFRENCIA',

    ADMINISTRATIVO_PESQUISA_PERFIS: 'ADMINISTRATIVO_PESQUISA_PERFIS',
    ADMINISTRATIVO_ERRO_PESQUISA_PERFIS: 'ADMINISTRATIVO_ERRO_PESQUISA_PERFIS',

    ADMINISTRATIVO_PESQUISA_PONTOS: 'ADMINISTRATIVO_PESQUISA_PONTOS',
    ADMINISTRATIVO_ERRO_PESQUISA_PONTOS: 'ADMINISTRATIVO_ERRO_PESQUISA_PONTOS',
    ADMINISTRATIVO_CADASTRAR_PONTOS: 'ADMINISTRATIVO_CADASTRAR_PONTOS',
    ADMINISTRATIVO_ERRO_CADASTRAR_PONTOS: 'ADMINISTRATIVO_ERRO_CADASTRAR_PONTOS',

    ADMINISTRATIVO_PESQUISA_EQUIPES: 'ADMINISTRATIVO_PESQUISA_EQUIPES',
    ADMINISTRATIVO_ERRO_PESQUISA_EQUIPES: 'ADMINISTRATIVO_ERRO_PESQUISA_EQUIPES',

    ADMINISTRATIVO_PESQUISA_PERMISSOES: 'ADMINISTRATIVO_PESQUISA_PERMISSOES',
    ADMINISTRATIVO_ERRO_PESQUISA_PERMISSOES: 'ADMINISTRATIVO_ERRO_PESQUISA_PERMISSOES',

    ADMINISTRATIVO_CADASTRO_PERMISSOES_PERFIL: 'ADMINISTRATIVO_CADASTRO_PERMISSOES_PERFIL',
    ADMINISTRATIVO_ERRO_CADASTRO_PERMISSOES_PERFIL: 'ADMINISTRATIVO_ERRO_CADASTRO_PERMISSOES_PERFIL',

    ADMINISTRATIVO_PESQUISAR_LOG_FUNCIONARIO: 'ADMINISTRATIVO_PESQUISAR_LOG_FUNCIONARIO',

    //Motivos de invalidação de exame
    ADMINISTRATIVO_PESQUISA_MOTIVO_INVALIDACAO_EXAME: 'ADMINISTRATIVO_PESQUISA_MOTIVO_INVALIDACAO_EXAME',
    ADMINISTRATIVO_ERRO_PESQUISA_MOTIVO_INVALIDACAO_EXAME: 'ADMINISTRATIVO_ERRO_PESQUISA_MOTIVO_INVALIDA_EXAME',
    ADMINISTRATIVO_CADASTRAR_MOTIVO_INVALIDACAO_EXAME: 'ADMINISTRATIVO_CADASTRAR_MOTIVO_INVALIDACAO_EXAME',
    ADMINISTRATIVO_ERRO_CADASTRAR_MOTIVO_INVALIDACAO_EXAME: 'ADMINISTRATIVO_ERRO_CADASTRO_MOTIVO_INVALIDA_EXAME',


    //Materiais suporte
    ADMINISTRATIVO_PESQUISA_MATERIAIS_SUPORTE: 'ADMINISTRATIVO_PESQUISA_MATERIAIS_SUPORTE',
    ADMINISTRATIVO_ERRO_PESQUISA_MATERIAIS_SUPORTE: 'ADMINISTRATIVO_ERRO_PESQUISA_MATERIAIS_SUPORTE',
    ADMINISTRATIVO_CADASTRO_MATERIAIS_SUPORTE: 'ADMINISTRATIVO_CADASTRO_MATERIAIS_SUPORTE',
    ADMINISTRATIVO_ERRO_CADASTRO_MATERIAIS_SUPORTE: 'ADMINISTRATIVO_ERRO_CADASTRO_MATERIAIS_SUPORTE',
    ADMINISTRATIVO_ERRO_ACESSO_ANEXO_MATERIAIS_SUPORTE: 'ADMINISTRATIVO_ERRO_ACESSO_ANEXO_MATERIAIS_SUPORTE',      

    // INSTITUIÇÕES
    ADMINISTRATIVO_PESQUISA_INSTITUICAO: 'ADMINISTRATIVO_PESQUISA_INSTITUICAO',
    ADMINISTRATIVO_ERRO_PESQUISA_INSTITUICAO: 'ADMINISTRATIVO_ERRO_PESQUISA_INSTITUICAO',
    ADMINISTRATIVO_CADASTRAR_INSTITUICAO: 'ADMINISTRATIVO_CADASTRAR_INTITUICAO',
    ADMINISTRATIVO_ERRO_CADASTRAR_INSTITUICAO: 'ADMINISTRATIVO_ERRO_CADASTRAR_INSTITUICAO',
    ADMINISTRATIVO_BAIXAR_MATERIAL: 'ADMINISTRATIVO_BAIXAR_MATERIAL',

    ADMINISTRATIVO_PESQUISA_ACESSO_INSTITUICAO: 'ADMINISTRATIVO_PESQUISA_ACESSO_INSTITUICAO',
    ADMINISTRATIVO_ERRO_PESQUISA_ACESSO_INSTITUICAO: 'ADMINISTRATIVO_ERRO_PESQUISA_ACESSO_INSTITUICAO',
    ADMINISTRATIVO_APROVAR_ACESSO_INSTITUICAO: 'ADMINISTRATIVO_APROVAR_ACESSO_INSTITUICAO',
    ADMINISTRATIVO_ERRO_APROVAR_ACESSO_INSTITUICAO: 'ADMINISTRATIVO_ERRO_APROVAR_ACESSO_INSTITUICAO',
    ADMINISTRATIVO_REJEITAR_ACESSO_INSTITUICAO: 'ADMINISTRATIVO_REJEITAR_ACESSO_INSTITUICAO',
    ADMINISTRATIVO_ERRO_REJEITAR_ACESSO_INSTITUICAO: 'ADMINISTRATIVO_ERRO_REJEITAR_ACESSO_INSTITUICAO',

    // TELECONSULTORIAS
    TELECONSULTORIAS_ERRO_ACESSO_ANEXO: 'TELECONSULTORIAS_ERRO_ACESSO_ANEXO',
    TELECONSULTORIAS_ACESSO_CC_POR_ID: 'TELECONSULTORIAS_ACESSO_CC_POR_ID',
    TELECONSULTORIAS_ERRO_ACESSO_CC_POR_ID: 'TELECONSULTORIAS_ERRO_ACESSO_CC_POR_ID',
    TELECONSULTORIAS_ACESSO_SOLICITACAO_POR_ID: 'TELECONSULTORIAS_ACESSO_SOLICITACAO_POR_ID',
    TELECONSULTORIAS_ERRO_ACESSO_SOLICITACAO_POR_ID: 'TELECONSULTORIAS_ERRO_ACESSO_SOLICITACAO_POR_ID',
    TELECONSULTORIAS_ACESSO_0800_POR_ID: 'TELECONSULTORIAS_ACESSO_0800_POR_ID',
    TELECONSULTORIAS_ERRO_ACESSO_0800_POR_ID: 'TELECONSULTORIAS_ERRO_ACESSO_0800_POR_ID',
    TELECONSULTORIAS_NOVA_SOLICITACAO: 'TELECONSULTORIAS_NOVA_SOLICITACAO',
    TELECONSULTORIAS_ERRO_NOVA_SOLICITACAO: 'TELECONSULTORIAS_ERRO_NOVA_SOLICITACAO',
    TELECONSULTORIAS_SALVAR_SOLICITACAO: 'TELECONSULTORIAS_SALVAR_SOLICITACAO',
    TELECONSULTORIAS_ERRO_SALVAR_SOLICITACAO: 'TELECONSULTORIAS_ERRO_SALVAR_SOLICITACAO',
    TELECONSULTORIAS_ERRO_AUTORREGULACAO: 'TELECONSULTORIAS_ERRO_AUTORREGULACAO',
    TELECONSULTORIAS_ERRO_SALVAR_MOVIMENTACAO: 'TELECONSULTORIAS_ERRO_SALVAR_MOVIMENTACAO',
    TELECONSULTORIAS_ERRO_INVALIDACAO: 'TELECONSULTORIAS_ERRO_INVALIDACAO',
    TELECONSULTORIAS_INVALIDACAO: 'TELECONSULTORIAS_INVALIDACAO',
    TELECONSULTORIAS_REGULACAO: 'TELECONSULTORIAS_REGULACAO',
    TELECONSULTORIAS_ERRO_REGULACAO: 'TELECONSULTORIAS_ERRO_REGULACAO',
    TELECONSULTORIAS_REGULACAO_MULTIPLA: 'TELECONSULTORIAS_REGULACAO_MULTIPLA',
    TELECONSULTORIAS_ERRO_REGULACAO_MULTIPLA: 'TELECONSULTORIAS_ERRO_REGULACAO_MULTIPLA',
    TELECONSULTORIAS_REGULACAO_TELE_SECUNDARIO: 'TELECONSULTORIAS_REGULACAO_TELE_SECUNDARIO',
    TELECONSULTORIAS_ERRO_REGULACAO_TELE_SECUNDARIO: 'TELECONSULTORIAS_ERRO_REGULACAO_TELE_SECUNDARIO',
    TELECONSULTORIAS_REGULACAO_REENCAMINHAMENTO: 'TELECONSULTORIAS_REGULACAO_REENCAMINHAMENTO',
    TELECONSULTORIAS_ERRO_APROVACAO: 'TELECONSULTORIAS_ERRO_APROVACAO',
    TELECONSULTORIAS_APROVACAO: 'TELECONSULTORIAS_APROVACAO',
    TELECONSULTORIAS_ERRO_ENVIO_EMAIL: 'TELECONSULTORIAS_ERRO_ENVIO_EMAIL',
    TELECONSULTORIAS_ERRO_RESPOSTA: 'TELECONSULTORIAS_ERRO_RESPOSTA',
    TELECONSULTORIAS_RESPOSTA: 'TELECONSULTORIAS_RESPOSTA',
    TELECONSULTORIAS_ERRO_RESPOSTA_SECUNDARIA: 'TELECONSULTORIAS_ERRO_RESPOSTA_SECUNDARIA',
    TELECONSULTORIAS_RESPOSTA_SECUNDARIA: 'TELECONSULTORIAS_RESPOSTA_SECUNDARIA',
    TELECONSULTORIAS_ERRO_DEVOLUCAO: 'TELECONSULTORIAS_ERRO_DEVOLUCAO',
    TELECONSULTORIAS_DEVOLUCAO: 'TELECONSULTORIAS_DEVOLUCAO',
    TELECONSULTORIAS_ERRO_AVALIACAO: 'TELECONSULTORIAS_ERRO_AVALIACAO',
    TELECONSULTORIAS_AVALIACAO: 'TELECONSULTORIAS_AVALIACAO',
    TELECONSULTORIAS_ERRO_REPLICA: 'TELECONSULTORIAS_ERRO_REPLICA',
    TELECONSULTORIAS_REPLICA: 'TELECONSULTORIAS_REPLICA',
    TELECONSULTORIAS_ERRO_TREPLICA: 'TELECONSULTORIAS_ERRO_TREPLICA',
    TELECONSULTORIAS_TREPLICA: 'TELECONSULTORIAS_TREPLICA',
    TELECONSULTORIAS_ERRO_FINALIZACAO_ADMINISTRATIVA: 'TELECONSULTORIAS_ERRO_FINALIZACAO_ADMINISTRATIVA',
    TELECONSULTORIAS_FINALIZACAO_ADMINISTRATIVA: 'TELECONSULTORIAS_FINALIZACAO_ADMINISTRATIVA',
    TELECONSULTORIAS_ERRO_ALTERAR_CLASSIFICACAO: 'TELECONSULTORIAS_ERRO_ALTERAR_CLASSIFICACAO',
    TELECONSULTORIAS_ALTERAR_CLASSIFICACAO: 'TELECONSULTORIAS_ALTERAR_CLASSIFICACAO',
    TELECONSULTORIAS_ERRO_ALTERAR_TELECONSULTOR: 'TELECONSULTORIAS_ERRO_ALTERAR_TELECONSULTOR',
    TELECONSULTORIAS_ALTERAR_TELECONSULTOR: 'TELECONSULTORIAS_ALTERAR_TELECONSULTOR',
    TELECONSULTORIAS_ERRO_ALTERAR_CLASSIFICACAO_CIDADE: 'TELECONSULTORIAS_ERRO_ALTERAR_CLASSIFICACAO_CIDADE',
    TELECONSULTORIAS_ALTERAR_CLASSIFICACAO_CIDADE: 'TELECONSULTORIAS_ALTERAR_CLASSIFICACAO_CIDADE',
    TELECONSULTORIAS_ERRO_ALTERAR_REGIAO_CIDADE: 'TELECONSULTORIAS_ERRO_ALTERAR_REGIAO_CIDADE',
    TELECONSULTORIAS_ALTERAR_REGIAO_CIDADE: 'TELECONSULTORIAS_ALTERAR_REGIAO_CIDADE',
    TELECONSULTORIAS_ERRO_ALTERAR_REGIAO_ANEXO: 'TELECONSULTORIAS_ERRO_ALTERAR_REGIAO_ANEXO',
    TELECONSULTORIAS_ALTERAR_REGIAO_ANEXO: 'TELECONSULTORIAS_ALTERAR_REGIAO_ANEXO',
    TELECONSULTORIAS_ERRO_EXCLUIR_RESTRICAO_ESPEC: 'TELECONSULTORIAS_ERRO_EXCLUIR_RESTRICAO_ESPEC',
    TELECONSULTORIAS_EXCLUIR_RESTRICAO_ESPECIFICACAO: 'TELECONSULTORIAS_EXCLUIR_RESTRICAO_ESPECIFICACAO',
    TELECONSULTORIAS_ERRO_SALVAR_RESTRICAO_ESPEC: 'TELECONSULTORIAS_ERRO_SALVAR_RESTRICAO_ESPEC',
    TELECONSULTORIAS_SALVAR_RESTRICAO_ESPECIFICACAO: 'TELECONSULTORIAS_SALVAR_RESTRICAO_ESPECIFICACAO',
    TELECONSULTORIAS_ERRO_EXCLUIR_RESTRICAO_IDADE: 'TELECONSULTORIAS_ERRO_EXCLUIR_RESTRICAO_IDADE',
    TELECONSULTORIAS_EXCLUIR_RESTRICAO_IDADE: 'TELECONSULTORIAS_EXCLUIR_RESTRICAO_IDADE',
    TELECONSULTORIAS_ERRO_SALVAR_RESTRICAO_IDADE: 'TELECONSULTORIAS_ERRO_SALVAR_RESTRICAO_IDADE',
    TELECONSULTORIAS_SALVAR_RESTRICAO_IDADE: 'TELECONSULTORIAS_SALVAR_RESTRICAO_IDADE',
    TELECONSULTORIAS_ERRO_SALVAR_RESTRICAO_GENERO: 'TELECONSULTORIAS_ERRO_SALVAR_RESTRICAO_GENERO',
    TELECONSULTORIAS_SALVAR_RESTRICAO_GENERO: 'TELECONSULTORIAS_SALVAR_RESTRICAO_GENERO',
    TELECONSULTORIAS_ERRO_SALVAR_RESTRICAO_INSTITUICAO: 'TELECONSULTORIAS_ERRO_SALVAR_RESTRICAO_INSTITUICAO',
    TELECONSULTORIAS_SALVAR_RESTRICAO_INSTITUICAO: 'TELECONSULTORIAS_SALVAR_RESTRICAO_INSTITUICAO',
    TELECONSULTORIAS_ERRO_EXCLUIR_RESTRICAO_GENERO: 'TELECONSULTORIAS_ERRO_EXCLUIR_RESTRICAO_GENERO',
    TELECONSULTORIAS_EXCLUIR_RESTRICAO_GENERO: 'TELECONSULTORIAS_EXCLUIR_RESTRICAO_GENERO',
    TELECONSULTORIAS_ERRO_SALVAR_LIBERACAO_CBO: 'TELECONSULTORIAS_ERRO_SALVAR_LIBERACAO_CBO',
    TELECONSULTORIAS_SALVAR_LIBERACAO_CBO: 'TELECONSULTORIAS_SALVAR_LIBERACAO_CBO',
    TELECONSULTORIAS_ERRO_EXCLUIR_LIBERACAO_CBO: 'TELECONSULTORIAS_ERRO_EXCLUIR_LIBERACAO_CBO',
    TELECONSULTORIAS_EXCLUIR_LIBERACAO_CBO: 'TELECONSULTORIAS_EXCLUIR_LIBERACAO_CBO',
    TELECONSULTORIAS_ERRO_SALVAR_LIBERACAO_VISUALIZADOR: 'TELECONSULTORIAS_ERRO_SALVAR_LIBERACAO_VISUALIZADOR',
    TELECONSULTORIAS_SALVAR_LIBERACAO_VISUALIZADOR: 'TELECONSULTORIAS_SALVAR_LIBERACAO_VISUALIZADOR',
    TELECONSULTORIAS_ERRO_SALVAR_AGENDA_PERSONALIZADA: 'TELECONSULTORIAS_ERRO_SALVAR_AGENDA_PERSONALIZADA',
    TELECONSULTORIAS_SALVAR_AGENDA_PERSONALIZADA: 'TELECONSULTORIAS_SALVAR_AGENDA_PERSONALIZADA',
    TELECONSULTORIAS_ERRO_SALVAR_AGENDA_CIRURGICA: 'TELECONSULTORIAS_ERRO_SALVAR_AGENDA_CIRURGICA',
    TELECONSULTORIAS_SALVAR_AGENDA_CIRURGICA: 'TELECONSULTORIAS_SALVAR_AGENDA_CIRURGICA',
    TELECONSULTORIAS_ERRO_SALVAR_AGENDA_INTEGRAL: 'TELECONSULTORIAS_ERRO_SALVAR_AGENDA_INTEGRAL',
    TELECONSULTORIAS_SALVAR_AGENDA_INTEGRAL: 'TELECONSULTORIAS_SALVAR_AGENDA_INTEGRAL',
    TELECONSULTORIAS_ERRO_SALVAR_LIBERACAO_REGULADOR: 'TELECONSULTORIAS_ERRO_SALVAR_LIBERACAO_REGULADOR',
    TELECONSULTORIAS_SALVAR_LIBERACAO_REGULADOR: 'TELECONSULTORIAS_SALVAR_LIBERACAO_REGULADOR',
    TELECONSULTORIAS_ERRO_SALVAR_LIBERACAO_SOLICITANTE: 'TELECONSULTORIAS_ERRO_SALVAR_LIBERACAO_SOLICITANTE',
    TELECONSULTORIAS_SALVAR_LIBERACAO_SOLICITANTE: 'TELECONSULTORIAS_SALVAR_LIBERACAO_SOLICITANTE',
    TELECONSULTORIAS_ERRO_SALVAR_ESPECIFICACAO: 'TELECONSULTORIAS_ERRO_SALVAR_ESPECIFICACAO',
    TELECONSULTORIAS_SALVAR_ESPECIFICACAO: 'TELECONSULTORIAS_SALVAR_ESPECIFICACAO',
    TELECONSULTORIAS_ERRO_SALVAR_NUTRICAO: 'TELECONSULTORIAS_ERRO_SALVAR_NUTRICAO',
    TELECONSULTORIAS_SALVAR_NUTRICAO: 'TELECONSULTORIAS_SALVAR_NUTRICAO',
    TELECONSULTORIAS_SALVAR_INSUMO: 'TELECONSULTORIAS_SALVAR_INSUMO',
    TELECONSULTORIAS_ERRO_SALVAR_INSUMO: 'TELECONSULTORIAS_ERRO_SALVAR_INSUMO',
    TELECONSULTORIAS_ERRO_SALVAR_MEDICAMENTO: 'TELECONSULTORIAS_ERRO_SALVAR_MEDICAMENTO',
    TELECONSULTORIAS_SALVAR_MEDICAMENTO: 'TELECONSULTORIAS_SALVAR_MEDICAMENTO',

    //Alteração de funcionário
    ADMINISTRATIVO_PESQUISA_ALTERACAO_FUNCIONARIO: 'ADMINISTRATIVO_PESQUISA_ALTERACAO_FUNCIONARIO',
    ADMINISTRATIVO_ERRO_PESQUISA_ALTERACAO_FUNCIONARIO: 'ADMINISTRATIVO_ERRO_PESQUISA_ALTERACAO_FUNCIONARIO',

    // Alteração de paciente
    ADMINISTRATIVO_ALTERACAO_PACIENTE: 'ADMINISTRATIVO_ALTERACAO_PACIENTE',
    ADMINISTRATIVO_ERRO_ALTERACAO_PACIENTE: 'ADMINISTRATIVO_ERRO_ALTERACAO_PACIENTE',

    //Triagem de auto-cadastro
    ADMINISTRATIVO_PESQUISA_TRIAGEM: 'ADMINISTRATIVO_PESQUISA_TRIAGEM',
    ADMINISTRATIVO_ERRO_PESQUISA_TRIAGEM: 'ADMINISTRATIVO_ERRO_PESQUISA_TRIAGEM',

    ADMINISTRATIVO_PESQUISA_DADOS_TRIAGEM: 'ADMINISTRATIVO_PESQUISA_DADOS_TRIAGEM',
    ADMINISTRATIVO_ERRO_PESQUISA_DADOS_TRIAGEM: 'ADMINISTRATIVO_ERRO_PESQUISA_DADOS_TRIAGEM',

    ADMINISTRATIVO_ACESSO_SOLICITACAO: 'ADMINISTRATIVO_ACESSO_SOLICITACAO',
    ADMINISTRATIVO_ERRO_ACESSO_SOLICITACAO: 'ADMINISTRATIVO_ERRO_ACESSO_SOLICITACAO',

    ADMINISTRATIVO_APROVACAO_SOLICITACAO: 'ADMINISTRATIVO_APROVACAO_SOLICITACAO',
    ADMINISTRATIVO_ERRO_APROVACAO_SOLICITACAO: 'ADMINISTRATIVO_ERRO_APROVACAO_SOLICITACAO',

    ADMINISTRATIVO_REJEITAR_SOLICITACAO: 'ADMINISTRATIVO_REJEITAR_SOLICITACAO',
    ADMINISTRATIVO_ERRO_REJEITAR_SOLICITACAO: 'ADMINISTRATIVO_ERRO_REJEITAR_SOLICITACAO',

    ADMINISTRATIVO_APROVAR_TRIAGEM_SOLICITACAO: 'ADMINISTRATIVO_APROVAR_TRIAGEM_SOLICITACAO',
    ADMINISTRATIVO_ERRO_APROVAR_TRIAGEM_SOLICITACAO: 'ADMINISTRATIVO_ERRO_APROVAR_TRIAGEM_SOLICITACAO',

    // CRUD de equipes de saúde da família (ESFs)
    ADMINISTRATIVO_PESQUISA_EQUIPES: 'ADMINISTRATIVO_PESQUISA_EQUIPES',
    ADMINISTRATIVO_ERRO_PESQUISA_EQUIPES: 'ADMINISTRATIVO_ERRO_PESQUISA_EQUIPES',
    ADMINISTRATIVO_CADASTRAR_EQUIPES: 'ADMINISTRATIVO_CADASTRAR_EQUIPES',
    ADMINISTRATIVO_ERRO_CADASTRAR_EQUIPES: 'ADMINISTRATIVO_ERRO_CADASTRAR_EQUIPES',

    //Redes de Telemedicina
    ADMINISTRATIVO_PESQUISA_REDE_TELEMEDICINA: 'ADMINISTRATIVO_PESQUISA_REDE_TELEMEDICINA',
    ADMINISTRATIVO_ERRO_PESQUISA_REDE_TELEMEDICINA: 'ADMINISTRATIVO_ERRO_PESQUISA_REDE_TELEMEDICINA',
    ADMINISTRATIVO_CADASTRAR_REDE_TELEMEDICINA: 'ADMINISTRATIVO_CADASTRAR_REDE_TELEMEDICINA',
    ADMINISTRATIVO_ERRO_CADASTRAR_REDE_TELEMEDICINA: 'ADMINISTRATIVO_ERRO_CADASTRAR_REDE_TELEMEDICINA',

    //Mensagens
    ADMINISTRATIVO_PESQUISA_MENSAGENS: 'ADMINISTRATIVO_PESQUISA_MENSAGENS',
    ADMINISTRATIVO_ERRO_PESQUISA_MENSAGENS: 'ADMINISTRATIVO_ERRO_PESQUISA_MENSAGENS',
    ADMINISTRATIVO_CADASTRAR_MENSAGENS: 'ADMINISTRATIVO_CADASTRAR_MENSAGENS',
    ADMINISTRATIVO_ERRO_CADASTRAR_MENSAGENS: 'ADMINISTRATIVO_ERRO_CADASTRAR_MENSAGENS',

    //Pacs
    ADMINISTRATIVO_PESQUISA_PACS: 'ADMINISTRATIVO_PESQUISA_PACS',
    ADMINISTRATIVO_ERRO_PESQUISA_PACS: 'ADMINISTRATIVO_ERRO_PESQUISA_PACS',
    ADMINISTRATIVO_CADASTRAR_PACS: 'ADMINISTRATIVO_CADASTRAR_PACS',
    ADMINISTRATIVO_ERRO_CADASTRAR_PACS: 'ADMINISTRATIVO_ERRO_CADASTRAR_PACS',

    // HELPDESK e TELEATENDIMENTO
    STT_WEB_ATENDIMENTO_CHAT_HELPDESK: 'STT_WEB_ATENDIMENTO_CHAT_HELPDESK',
    STT_WEB_ATENDIMENTO_CHAT_TELEATENDIMENTO: 'STT_WEB_ATENDIMENTO_CHAT_TELEATENDIMENTO',

    //ELETROCARDIOGRAFIA
    ELETROCARDIOGRAFIA_SOLICITAR: 'ELETROCARDIOGRAFIA_SOLICITAR',
    ELETROCARDIOGRAFIA_ERRO_SOLICITAR: 'ELETROCARDIOGRAFIA_ERRO_SOLICITAR',

    // CATEGORIAS DE LOG
    CATEGORIA: {
        PRIORIZACAO_EXAME: 1,
        INVALIDACAO_EXAME: 5,
        REENVIO_SENHA: 2,
        CADASTRO_USUARIO: 3,
        ALTERACAO_USUARIO: 4,
        INVALIDAR_EXAME: 5,
        CADASTRO_EQUIPE: 6,
        CADASTRO_EQUIPAMENTO: 7,
        CADASTRO_FUNCIONARIO: 8,
        ALTERACAO_FUNCIONARIO: 9,
        ATENDIMENTO_FUNCIONARIO_HELPDESK: 10,
        ATENDIMENTO_PACIENTE_HELPDESK: 11,
        CADASTRO_INSTITUICAO: 12,
        PACIENTE_VINCULADO_EXAME: 13
    },

    // ALOFONO
    ALOFONO_ERRO_INICIAR: 'ALOFONO_ERRO_INICIAR',
    ALOFONO_ERRO_FINALIZAR: 'ALOFONO_ERRO_FINALIZAR',
    ALOFONO_ERRO_SALVAR_ITERACAO: 'ALOFONO_ERRO_SALVAR_ITERACAO',
    ALOFONO_ERRO_SALVAR_QUESTIONARIO: 'ALOFONO_ERRO_SALVAR_QUESTIONARIO',

    TELEATENDIMENTO_ACESSO: 'TELEATENDIMENTO_ACESSO',
    TELEATENDIMENTO_ACESSO_ANEXO: 'TELEATENDIMENTO_ACESSO_ANEXO',
    TELEATENDIMENTO_SAIDA: 'TELEATENDIMENTO_SAIDA',
    TELEATENDIMENTO_SELECAO_PACIENTE: 'TELEATENDIMENTO_SELECAO_PACIENTE',
    TELEATENDIMENTO_AUDIO_VIDEO: 'TELEATENDIMENTO_AUDIO_VIDEO',
    TELEATENDIMENTO_ENVIO_ANEXO: 'TELEATENDIMENTO_ENVIO_ANEXO',
    TELEATENDIMENTO_FINALIZADO: 'TELEATENDIMENTO_FINALIZADO',

    TELESSAUDE_WEB_ACESSO_CURSO: 'TELESSAUDE_WEB_ACESSO_CURSO',
    TELESSAUDE_WEB_ACESSO_CURSO_ERRO: 'TELESSAUDE_WEB_ACESSO_CURSO_ERRO',
    TELESSAUDE_WEB_ACESSO_EVENTOS_ANDAMENTO: 'TELESSAUDE_WEB_ACESSO_EVENTOS_ANDAMENTO',
    TELESSAUDE_WEB_REGISTRO_ACESSO_WEB: 'TELESSAUDE_WEB_REGISTRO_ACESSO_WEB',

    // TELE-EDUCAÇÃO
    TELE_EDUCACAO_BAIXAR_MATERIAL: 'TELE_EDUCACAO_BAIXAR_MATERIAL',
    PESQUISAR_WEBCONFERENCIA: 'PESQUISAR_WEBCONFERENCIA',
    ERRO_PESQUISAR_WEBCONFERENCIA: 'ERRO_PESQUISAR_WEBCONFERENCIA',
    WEBCONFERENCIA_SALVAR: 'WEBCONFERENCIA_SALVAR',
    WEBCONFERENCIA_ERRO_SALVAR: 'WEBCONFERENCIA_ERRO_SALVAR',
    TELE_EDUCACAO_SALVAR_MATERIAL: 'TELE_EDUCACAO_SALVAR_MATERIAL',
    TELE_EDUCACAO_ERRO_SALVAR_MATERIAL: 'TELE_EDUCACAO_ERRO_SALVAR_MATERIAL',
    TELE_EDUCACAO_REMOCAO_ACESSO: 'TELE_EDUCACAO_REMOCAO_ACESSO',
    TELE_EDUCACAO_ERRO_REMOCAO_ACESSO: 'TELE_EDUCACAO_ERRO_REMOCAO_ACESSO',
    TELE_EDUCACAO_INATIVAR: 'TELE_EDUCACAO_INATIVAR',
    TELE_EDUCACAO_ERRO_INATIVAR: 'TELE_EDUCACAO_ERRO_INATIVAR',
    PESQUISAR_CURSO: 'PESQUISAR_CURSO',
    ERRO_PESQUISAR_CURSO: 'ERRO_PESQUISAR_CURSO',
    CURSO_SALVAR: 'CURSO_SALVAR',
    CURSO_ERRO_SALVAR: 'CURSO_ERRO_SALVAR',

    // SISTEMA
    CADASTRO_FUNCIONARIO_SISTEMA: 'CADASTRO_FUNCIONARIO_SISTEMA',
    ERRO_CADASTRO_FUNCIONARIO_SISTEMA: 'ERRO_CADASTRO_FUNCIONARIO_SISTEMA',
    CADASTRO_TELE_0800_SISTEMA: 'CADASTRO_TELE_0800_SISTEMA',
    ERRO_CADASTRO_TELE_0800_SISTEMA: 'ERRO_CADASTRO_TELE_0800_SISTEMA',
    CADASTRO_TELE_CC_SISTEMA: 'CADASTRO_TELE_CC_SISTEMA',
    ERRO_CADASTRO_TELE_CC_SISTEMA: 'ERRO_CADASTRO_TELE_CC_SISTEMA',
    CADASTRO_TELE_SOLICITACAO_SISTEMA: 'CADASTRO_TELE_SOLICITACAO_SISTEMA',
    ERRO_CADASTRO_TELE_SOLICITACAO_SISTEMA: 'ERRO_CADASTRO_TELE_SOLICITACAO_SISTEMA',

    // APAC
    PESQUISAR_PROCESSO_APAC: 'STT2_WEB_PESQUISAR_PROCESSO_APAC',
    ERRO_PESQUISAR_PROCESSO_APAC: 'STT2_WEB_ERRO_PESQUISAR_PROCESSO_APAC',
    BAIXAR_ARQUIVO_APAC: 'STT2_WEB_BAIXAR_ARQUIVO_APAC',
    ERRO_BAIXAR_ARQUIVO_APAC: 'STT2_WEB_ERRO_BAIXAR_ARQUIVO_APAC',
    INICIAR_NOVA_APAC: 'STT2_WEB_INICIAR_NOVA_APAC',
    ERRO_INICIAR_NOVA_APAC: 'STT2_WEB_ERRO_INICIAR_NOVA_APAC',
    EDITAR_APAC: 'STT2_WEB_EDITAR_APAC',
    ERRO_EDITAR_APAC: 'STT2_WEB_ERRO_EDITAR_APAC',
    ENCAMINHAR_APAC: 'STT2_WEB_ENCAMINHAR_APAC',
    ERRO_ENCAMINHAR_APAC: 'STT2_WEB_ERRO_ENCAMINHAR_APAC',
    IMPRIMIR_APAC: 'STT2_WEB_IMPRIMIR_APAC',
    ERRO_IMPRIMIR_APAC: 'STT2_WEB_ERRO_IMPRIMIR_APAC',
    CANCELAMENTO_APAC: 'STT2_WEB_CANCELAMENTO_APAC',
    ERRO_CANCELAMENTO_APAC: 'STT2_WEB_ERRO_CANCELAMENTO_APAC',
    ERRO_PESQUISA_MOTIVOS_DEVOLUCAO_APAC: 'STT2_WEB_ERRO_PESQUISA_MOTIVOS_DEVOLUCAO_APAC',
    DEVOLUCAO_APAC: 'STT2_WEB_DEVOLUCAO_APAC',
    ERRO_DEVOLUCAO_APAC: 'STT2_WEB_ERRO_DEVOLUCAO_APAC',
    AUTORIZAR_APAC: 'STT2_WEB_AUTORIZAR_APAC',
    ERRO_AUTORIZAR_APAC: 'STT2_WEB_ERRO_AUTORIZAR_APAC',
    ENCAMINHAR_AUTORIZADOR: 'STT2_WEB_ENCAMINHAR_AUTORIZADOR',
    ERRO_ENCAMINHAR_AUTORIZADOR: 'STT2_WEB_ERRO_ENCAMINHAR_AUTORIZADOR',
    APROVACAO_APAC: 'STT2_WEB_APROVACAO_APAC',
    ERRO_APROVACAO_APAC: 'STT2_WEB_ERRO_APROVACAO_APAC',
    INVALIDACAO_APAC: 'STT2_WEB_INVALIDACAO_APAC',
    ERRO_INVALIDACAO_APAC: 'STT2_WEB_ERRO_INVALIDACAO_APAC',

    // CONECTA
    CONECTA_SALVAR_REDE: 'CONECTA_SALVAR_REDE',
    CONECTA_ERRO_SALVAR_REDE: 'CONECTA_ERRO_SALVAR_REDE',
    CONECTA_SALVAR_CATEGORIA: 'CONECTA_SALVAR_CATEGORIA',
    CONECTA_ERRO_SALVAR_CATEGORIA: 'CONECTA_ERRO_SALVAR_CATEGORIA',
    CONECTA_SALVAR_MOTIVO_CANCELAMENTO: 'CONECTA_SALVAR_MOTIVO_CANCELAMENTO',
    CONECTA_ERRO_SALVAR_MOTIVO_CANCELAMENTO: 'CONECTA_ERRO_SALVAR_MOTIVO_CANCELAMENTO',
    CONECTA_SALVAR_AGENDAMENTO: 'CONECTA_SALVAR_AGENDAMENTO',
    CONECTA_ERRO_SALVAR_AGENDAMENTO: 'CONECTA_ERRO_SALVAR_AGENDAMENTO',
    CONECTA_ERRO_SALVAR_MOVIMENTACAO_AGENDAMENTO: 'CONECTA_ERRO_SALVAR_MOVIMENTACAO_AGENDAMENTO',
    CONECTA_CANCELAR_AGENDAMENTO: 'CONECTA_CANCELAR_AGENDAMENTO',
    CONECTA_ERRO_CANCELAR_AGENDAMENTO: 'CONECTA_ERRO_CANCELAR_AGENDAMENTO',
    CONECTA_ACEITAR_RECUSAR_AGENDAMENTO: 'CONECTA_ACEITAR_RECUSAR_AGENDAMENTO',
    CONECTA_ERRO_ACEITAR_RECUSAR_AGENDAMENTO: 'CONECTA_ERRO_ACEITAR_RECUSAR_AGENDAMENTO',
    CONECTA_EVENTO_ACESSO: 'CONECTA_EVENTO_ACESSO',
    CONECTA_EVENTO_ACESSO_ANEXO: 'CONECTA_EVENTO_ACESSO_ANEXO',
    CONECTA_EVENTO_SAIDA: 'CONECTA_EVENTO_SAIDA',
    CONECTA_EVENTO_AUDIO_VIDEO: 'CONECTA_EVENTO_AUDIO_VIDEO',
    CONECTA_EVENTO_ENVIO_ANEXO: 'CONECTA_EVENTO_ENVIO_ANEXO',
    CONECTA_EVENTO_FINALIZADO: 'CONECTA_EVENTO_FINALIZADO',
}

const REDIS = {
    OAUTH2: 0,
    TELEATENDIMENTO: 1,
    ANALISE_AGENDADOR: 2,
    HELPDESK: 3,
    CONECTA: 4,
}

const STATUS_INTEGRACAO_ESTUDO_DICOM = {
    0: "Na fila",
    1: "Em processamento",
    2: "Processado",
    "-1": "Erro",
    10: "Invalidado",
    "-10": "Erro na invalidação",
    21: "Migração em processamento",
    22: "Migração processado",
    "-21": "Erro na migração",
};

const TIPOS_SENHA = {
    NOME: 'nome',
    CPF: 'cpf',
    DATA: 'dataNascimento',
    EMAIL: 'email',
    LOGIN: 'login'
}

module.exports = { TIPOS_SENHA, PESQUISA, PERFIL, TIPO_CONTATO, LOG, REDIS, MODULOS_EXAME, STATUS_INTEGRACAO_ESTUDO_DICOM };
