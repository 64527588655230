import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Login from './login';
import EsqueceuSenha from './esqueceu-senha';
import RedefinirSenha from './redefinir-senha';
import TokenInvalido from './token-invalido';
import Interno from './interno';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
    SttExternal as SttExterno,
    SttHeading,
    SttPaper,
    SttBox,
    SttGrid,
    SttLink,
    SttNotification,
    SttAlertTitle
} from '@stt-componentes/core';
import translate from '../translate';

/**
 * Definição de estilos específicos da página
 */
const useStyles = makeStyles((theme) => ({
    forms: {
        alignItems: 'stretch',
        flexDirection: 'row',
        display: 'flex',
        padding: '25px',
        flexWrap: 'wrap'
    },
    formColumn: {
        backgroundColor: theme.palette.background.default,
        border: '2px solid',
        borderColor: props => {
            switch (props.basename) {
                case 'ufpa':
                    return '#000000';
                default:
                    return '#D9D9D9';
            }
        },
        display: 'flex',
        margin: 'auto',
        width: theme.spacing(35),
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(2),
        background: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'linear-gradient(to bottom, #007335 0, #007335 70px, #85cde5 70px, #85cde5 100%)';
            }
        }
    },
    input: {
        width: '80%'
    },
    heading: {
        margin: theme.spacing(2),
        color: props => {
            switch (props.basename) {
                case 'ufpa':
                    return '#ffffff';
            }
        },
        textTransform: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'uppercase';
            }
        },
        fontFamily: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'Sans-serif';
            }
        },
    },
    box: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    link: {
        fontSize: '1rem',
        cursor: 'pointer',
        color: '#ffffff',
        textTransform: 'uppercase',
        fontFamily: "Sans-serif"
    },
    divLink: {
        margin: 'auto',
        width: theme.spacing(40),
        marginBottom: theme.spacing(1)
    },
    spanLink: {
        color: '#93c01f',
        transform: 'rotate(180deg)',
        display: 'inline-block'
    },
    gridContainer: {
        width: '100%',
        background: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'linear-gradient(to bottom, #c41a1b 0, #c41a1b 99px, #93c01f 99px, #93c01f 100%)';
            }
        }
    },
    notification: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    gridMapa: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));

const Index = ({ strings, ...props }) => {
    // Função de tradução
    const [t] = useTranslation(['public']);

    let propsCss = {
        basename: global.gConfig.basename
    };

    // Classes css
    const classes = useStyles(propsCss);

    const Externo = () => {
        return (
            <SttExterno
                instancia={{
                    logoHeader: global.gConfig.logo_header,
                    logoFooter: global.gConfig.logo_footer
                }}
                basename={global.gConfig.basename}
                {...props}
            >

                <SttGrid container direction='column' alignItems='center'>
                    {
                        global.gConfig.manutencao.sistema_em_manutencao &&
                        <SttGrid item xs={12} md={8} lg={4} xl={3}>
                            <SttNotification severity="warning" className={classes.notificacao}>
                                <SttAlertTitle>{strings.atencao}</SttAlertTitle>
                                {global.gConfig.manutencao.mensagem}
                            </SttNotification>
                        </SttGrid>
                    }


                    <SttGrid item sm={12} xs={12}>
                        <SttHeading
                            variant="h1"
                            color="primary"
                            className={classes.heading}
                        >
                            {global.gConfig.instancia_nome}
                        </SttHeading>
                    </SttGrid>
                    <SttGrid item sm={12} xs={12} className={classes.gridContainer}>
                        {
                            global.gConfig.basename === 'ufpa' &&
                            <div className={classes.divLink}>
                                <SttLink className={classes.link} href={'https://telessaude.chu.ufpa.br/'} underline='none'><span className={classes.spanLink}>&#10148;</span>Página inicial</SttLink>
                            </div>
                        }

                        <SttPaper className={classes.formColumn}>
                            <SttBox className={classes.box} letterSpacing={1}>
                                <Switch>
                                    <Route
                                        path="/"
                                        exact
                                    >
                                        <Login />
                                    </Route>
                                    <Route
                                        path="/esqueceu-senha"
                                        component={EsqueceuSenha}
                                    />
                                    <Route
                                        path="/redefinir-senha"
                                        component={RedefinirSenha}
                                    />
                                    <Route
                                        path="/token-invalido"
                                        component={TokenInvalido}
                                    />
                                </Switch>
                            </SttBox>
                        </SttPaper>
                    </SttGrid>
                    <SttGrid item sm={12} xs={12} style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', }}>
                        {
                            global.gConfig.url_base_mapa &&
                            <SttLink href={global.gConfig.url_base_mapa} target={`_blank`} variant="h3" style={{ marginTop: '10px' }}>
                                Mapa dinâmico
                            </SttLink>
                        }
                    </SttGrid>
                </SttGrid>
            </SttExterno>
        )
    }

    return (
        <BrowserRouter basename={`/${global.gConfig.basename}`}>
            <Switch>
                <Route
                    path={["/meus-dados", '/inicio', '/alteracao-senha', '/alteracao-foto', '/log-senha']}
                    component={Interno}
                />
                <Route
                    component={Externo}
                />
            </Switch>
        </BrowserRouter>

    );
};


export default translate('Index')(Index);
